import React from "react";
import { Link } from "react-router-dom";

const ProductCard = ({ product }) => {
  return (
    <div className="bg-white rounded-lg shadow-md hover:shadow-lg transition transform hover:scale-105">
      <img
        src={product.image}
        alt={product.name}
        className="rounded-t-lg h-48 w-full object-cover"
      />
      <div className="p-4">
        <h3 className="font-bold text-lg text-gray-800">{product.name}</h3>
        <p className="text-blue-600 font-semibold mt-2">{product.price} AED</p>
        <Link
          to={`/product/${product.id}`}
          className="block mt-4 text-center bg-blue-500 text-white py-2 rounded hover:bg-blue-600"
        >
          View Details
        </Link>
      </div>
    </div>
  );
};

export default ProductCard;
