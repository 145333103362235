import React from 'react';

const TermsOfService = () => {
  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-6">Terms of Service</h1>
      <div className="space-y-4">
        <section>
          <h2 className="text-2xl font-semibold mb-2">1. Acceptance of Terms</h2>
          <p>By accessing and using Al Noori Store's services, you agree to comply with and be bound by these Terms of Service.</p>
        </section>
        <section>
          <h2 className="text-2xl font-semibold mb-2">2. Use of Service</h2>
          <p>You agree to use our service for lawful purposes only and in a way that does not infringe upon the rights of others or inhibit their use of the service.</p>
        </section>
        <section>
          <h2 className="text-2xl font-semibold mb-2">3. Product Information</h2>
          <p>We strive to provide accurate product information, but we do not warrant that product descriptions or other content is accurate, complete, or error-free.</p>
        </section>
        <section>
          <h2 className="text-2xl font-semibold mb-2">4. Pricing and Availability</h2>
          <p>Prices and availability of products are subject to change without notice. We reserve the right to limit quantities of purchased items.</p>
        </section>
        <section>
          <h2 className="text-2xl font-semibold mb-2">5. User Accounts</h2>
          <p>You are responsible for maintaining the confidentiality of your account and password. You agree to accept responsibility for all activities that occur under your account.</p>
        </section>
      </div>
    </div>
  );
};

export default TermsOfService;