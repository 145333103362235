const products = [
    {
      id: 1,
      name: "Canon EOS 90D DSLR Camera",
      price: 5499,
      currency: "AED",
      image: ["/assets/sony-alpha-a7-iii/1.webp", "/assets/sony-alpha-a7-iii/2.webp", "/assets/sony-alpha-a7-iii/3.webp", "/assets/sony-alpha-a7-iii/4.webp"],
      category: "Camera",
      description: "The Canon EOS 90D is a versatile DSLR camera that delivers high-quality images and videos. It's perfect for both enthusiasts and professionals looking for a reliable and feature-rich camera.",
      specifications: [
        "32.5 Megapixel APS-C CMOS Sensor",
        "DIGIC 8 Image Processor",
        "UHD 4K30p & Full HD 120p Video Recording",
        "45-Point All Cross-Type AF System",
        "Dual Pixel CMOS AF with 5481 AF Points",
        "220,000-Pixel AE Metering Sensor",
        '3" 1.04m-Dot Vari-Angle Touchscreen LCD',
        "10 fps Shooting, ISO 100-25600",
        "Built-In Wi-Fi and Bluetooth",
        "EOS iTR AF, Electronic Shutter Function"
      ],
      reviews: [
        { id: 1, author: "John D.", rating: 5, comment: "Excellent camera, highly recommended!" },
        { id: 2, author: "Sarah M.", rating: 4, comment: "Great value for money, but the learning curve is steep." },
        { id: 3, author: "Mike R.", rating: 5, comment: "Professional quality images, couldn't be happier!" },
      ]
    },
    {
      id: 2,
      name: "Sony Alpha a7 III",
      price: 7899,
      currency: "AED",
      image: ["/assets/sony-alpha-a7-iii/1.webp", "/assets/sony-alpha-a7-iii/2.webp", "/assets/sony-alpha-a7-iii/3.webp", "/assets/sony-alpha-a7-iii/4.webp"],
      category: "Camera",
      description: "The Sony Alpha a7 III is a full-frame mirrorless camera that offers exceptional image quality, fast performance, and advanced features in a compact body.",
      specifications: [
        "24.2MP Full-Frame Exmor R BSI CMOS Sensor",
        "BIONZ X Image Processor & Front-End LSI",
        "693-Point Hybrid AF System",
        "UHD 4K30p Video with HLG & S-Log3 Gammas",
        "2.36m-Dot Tru-Finder OLED EVF",
        "3.0'' 922k-Dot Tilting Touchscreen LCD",
        "5-Axis SteadyShot INSIDE Stabilization",
        "ISO 100-51200, Extended ISO 50-204800",
        "10 fps Shooting with AF/AE Tracking",
        "Built-In Wi-Fi and NFC, Dual SD Slots"
      ],
      reviews: [
        { id: 1, author: "Emily L.", rating: 5, comment: "Amazing low-light performance and autofocus!" },
        { id: 2, author: "David K.", rating: 4, comment: "Versatile camera, but menu system can be complex." },
        { id: 3, author: "Lisa T.", rating: 5, comment: "Perfect for both photography and videography." },
      ]
    },
    {
      id: 3,
      name: "DJI Ronin-S Gimbal",
      price: 2799,
      currency: "AED",
      image: ["/assets/dji-ronin/1.webp", "/assets/dji-ronin/2.webp", "/assets/dji-ronin/3.webp", "/assets/dji-ronin/4.webp", "/assets/dji-ronin/5.webp", "/assets/dji-ronin/6.webp", "/assets/dji-ronin/7.webp", "/assets/dji-ronin/8.webp", "/assets/dji-ronin/9.webp", "/assets/dji-ronin/10.webp", "/assets/dji-ronin/11.webp", "/assets/dji-ronin/12.webp", "/assets/dji-ronin/13.webp", "/assets/dji-ronin/14.webp"],
      category: "Gimbal",
      description: "The DJI Ronin-S is a versatile 3-axis stabilizer for DSLR and mirrorless cameras, offering smooth and stable footage for professional videographers and filmmakers.",
      specifications: [
        "3-Axis Stabilization",
        "8.8 lb Payload",
        "One-Handed Operation",
        "Mobile Device Holder",
        "Sport Mode for Fast Movements",
        "360° Roll",
        "Focus Wheel",
        "Customizable Parameter Groups",
        "Automated Smart Shooting Modes",
        "Supports Camera Control"
      ],
      reviews: [
        { id: 1, author: "Alex M.", rating: 5, comment: "Incredible stabilization, transformed my videography!" },
        { id: 2, author: "Sophia R.", rating: 4, comment: "Bit heavy for long shoots, but results are worth it." },
        { id: 3, author: "Chris B.", rating: 5, comment: "Professional-grade gimbal at a reasonable price." },
      ]
    },
    {
        id: 4,
        name: "Nikon Z6 II Mirrorless Camera",
        price: 7499,
        currency: "AED",
        images: ["/assets/nikon-z6-1.jpg", "/assets/nikon-z6-2.jpg", "/assets/nikon-z6-3.jpg"],
        category: "Camera",
        description: "The Nikon Z6 II is a versatile full-frame mirrorless camera that offers excellent image quality, fast performance, and robust video capabilities.",
        specifications: [
          "24.5MP FX-Format BSI CMOS Sensor",
          "Dual EXPEED 6 Image Processors",
          "UHD 4K60 Video; N-Log & 10-Bit HDMI Out",
          "273-Point Phase-Detect AF System",
          "14 fps Cont. Shooting, ISO 100-51200",
          "5-Axis In-Body Vibration Reduction",
          "3.6m-Dot OLED Electronic Viewfinder",
          "3.2'' 2.1m-Dot Tilting Touchscreen LCD",
          "Dual Memory Card Slots (CFexpress/XQD & SD)",
          "Built-In Wi-Fi and Bluetooth"
        ],
        reviews: [
          { id: 1, author: "Mark S.", rating: 5, comment: "Exceptional low-light performance and image quality!" },
          { id: 2, author: "Rachel T.", rating: 4, comment: "Great camera, but the menu system takes some getting used to." },
        ]
      },
      {
        id: 5,
        name: "DJI Mavic Air 2 Drone",
        price: 3299,
        currency: "AED",
        images: ["/assets/mavic-air-2-1.jpg", "/assets/mavic-air-2-2.jpg", "/assets/mavic-air-2-3.jpg"],
        category: "Drone",
        description: "The DJI Mavic Air 2 is a powerful and portable drone that offers high-quality 4K video, intelligent shooting modes, and extended flight time.",
        specifications: [
          "1/2'' CMOS Sensor, 48MP Photos",
          "Up to 4K60 Video & 240 fps 1080p Slow Motion",
          "3-Axis Gimbal with 4K Camera",
          "Up to 34 Minutes of Flight Time",
          "OcuSync 2.0 10km HD Transmission",
          "FocusTrack Subject Tracking Modes",
          "Obstacle Sensing in 3 Directions",
          "HDR Video & 8K Hyperlapse",
          "APAS 3.0 Advanced Pilot Assistance",
          "ActiveTrack 3.0 & Point of Interest 3.0"
        ],
        reviews: [
          { id: 1, author: "Tom H.", rating: 5, comment: "Amazing drone for both beginners and pros!" },
          { id: 2, author: "Emma W.", rating: 4, comment: "Great features, but be mindful of local drone regulations." },
        ]
      },
      {
        id: 6,
        name: "Godox AD200Pro Pocket Flash",
        price: 1199,
        currency: "AED",
        images: ["/assets/godox-ad200-1.jpg", "/assets/godox-ad200-2.jpg", "/assets/godox-ad200-3.jpg"],
        category: "Lighting",
        description: "The Godox AD200Pro is a versatile and portable flash unit that offers high power output in a compact form factor.",
        specifications: [
          "200Ws Output",
          "Interchangeable Flash Head System",
          "1/8000 Sec Flash Duration",
          "0.01-1.8 Sec Recycle Time",
          "2900mAh Lithium Battery",
          "TTL / Manual / Multi Flash Modes",
          "Built-in 2.4G Wireless X System",
          "Stable Color Temperature Mode",
          "12 Custom Functions",
          "Large LCD Display"
        ],
        reviews: [
          { id: 1, author: "Sarah L.", rating: 5, comment: "Powerful flash in a compact package. Love it!" },
          { id: 2, author: "Mike R.", rating: 4, comment: "Great for on-location shoots, but can be complex for beginners." },
        ]
      },
      {
        id: 7,
        name: "Manfrotto MT055XPRO3 Tripod",
        price: 899,
        currency: "AED",
        images: ["/assets/manfrotto-055-1.jpg", "/assets/manfrotto-055-2.jpg", "/assets/manfrotto-055-3.jpg"],
        category: "Tripod",
        description: "The Manfrotto MT055XPRO3 is a versatile and sturdy tripod designed for professional photographers and videographers.",
        specifications: [
          "Aluminum Construction",
          "Maximum Height: 170 cm",
          "Minimum Height: 9 cm",
          "Folded Length: 63 cm",
          "Maximum Load Capacity: 9 kg",
          "Quick Power Lock System",
          "90° Column for Macro Shooting",
          "Easy Link Connection for Accessories",
          "Bubble Level",
          "3 Leg Angle Positions"
        ],
        reviews: [
          { id: 1, author: "Chris B.", rating: 5, comment: "Rock-solid stability. Perfect for long exposures!" },
          { id: 2, author: "Lisa M.", rating: 4, comment: "Bit heavy for travel, but unbeatable performance." },
        ]
      },
      {
        id: 8,
        name: "Rode VideoMic Pro+ Shotgun Microphone",
        price: 699,
        currency: "AED",
        images: ["/assets/rode-videomic-1.jpg", "/assets/rode-videomic-2.jpg", "/assets/rode-videomic-3.jpg"],
        category: "Audio",
        description: "The Rode VideoMic Pro+ is a high-quality on-camera shotgun microphone designed for professional video production.",
        specifications: [
          "Supercardioid Polar Pattern",
          "Rycote Lyre Shock Mount",
          "Digital Switching",
          "2-Stage High Pass Filter",
          "3-Stage Gain Control",
          "Safety Channel",
          "Automatic Power Function",
          "USB-C Connectivity",
          "Rechargeable Battery",
          "3.5mm TRS Output"
        ],
        reviews: [
          { id: 1, author: "David W.", rating: 3, comment: "Significantly improved my video audio quality!" },
          { id: 2, author: "Anna K.", rating: 3, comment: "Great mic, but a bit pricey for hobbyists." },
        ]
      },
      {
        id: 9,
        name: "Profoto B10 Plus AirTTL Studio Light",
        price: 3999,
        currency: "AED",
        images: ["/assets/profoto-b10-1.jpg", "/assets/profoto-b10-2.jpg", "/assets/profoto-b10-3.jpg"],
        category: "Lighting",
        description: "The Profoto B10 Plus is a powerful and portable studio light that offers both flash and continuous light capabilities.",
        specifications: [
          "500Ws Flash Output",
          "2500 Lumens Continuous Light",
          "HSS, TTL, and AirTTL Compatible",
          "0.05-2.0 Sec Recycle Time",
          "1.5 Sec Flash Duration",
          "Adjustable Color Temperature: 3000-6500K",
          "Bluetooth Connectivity",
          "Removable Lithium-Ion Battery",
          "Compact and Lightweight Design",
          "Compatible with Profoto Light Shaping Tools"
        ],
        reviews: [
          { id: 1, author: "Emily R.", rating: 2, comment: "Studio-quality lighting in a portable package!" },
          { id: 2, author: "James T.", rating: 3, comment: "Excellent light, but requires a significant investment." },
        ]
      },
      {
        id: 10,
        name: "DJI RSC 2 Gimbal Stabilizer",
        price: 1799,
        currency: "AED",
        images: ["/assets/dji-rsc2-1.jpg", "/assets/dji-rsc2-2.jpg", "/assets/dji-rsc2-3.jpg"],
        category: "Gimbal",
        description: "The DJI RSC 2 is a compact and powerful 3-axis gimbal stabilizer designed for mirrorless and DSLR cameras.",
        specifications: [
          "3-Axis Stabilization",
          "6.6 lb Payload",
          "Foldable Design",
          "1'' OLED Screen",
          "SuperSmooth Mode",
          "Time Tunnel Mode",
          "360° Roll",
          "12-Hour Battery Life",
          "Automated Axis Locks",
          "Supports Camera Control"
        ],
        reviews: [
          { id: 1, author: "Sophie L.", rating: 2, comment: "Incredibly smooth footage. A game-changer for my videos!" },
          { id: 2, author: "Mark D.", rating: 3, comment: "Great stabilization, but the learning curve is steep." },
        ]
      },
      {
        id: 11,
        name: "Atomos Ninja V 5\" 4K HDMI Recording Monitor",
        price: 2499,
        currency: "AED",
        images: ["/assets/atomos-ninja-1.jpg", "/assets/atomos-ninja-2.jpg", "/assets/atomos-ninja-3.jpg"],
        category: "Monitor",
        description: "The Atomos Ninja V is a 5\" 4K HDMI recording monitor that offers professional-grade monitoring and recording capabilities.",
        specifications: [
          "5\" 1920x1080 Touchscreen Display",
          "4K HDMI Input",
          "Records 4K60p Video",
          "10-Bit 4:2:2 ProRes & DNx Recording",
          "HDR Monitoring",
          "1000 cd/m² Brightness",
          "AtomHDR Technology",
          "3D LUTs",
          "Waveform & Vectorscope Monitoring",
          "Sony NP-F Battery Compatible"
        ],
        reviews: [
          { id: 1, author: "Alex K.", rating: 3, comment: "Excellent monitor for both on-set and in-studio use!" },
          { id: 2, author: "Olivia M.", rating: 3.5, comment: "Great features, but the battery life could be better." },
        ]
      },
    // Add more products with detailed information...
  ];
  
  export default products;