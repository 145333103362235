import React from 'react';
import ProductCardWithHover from './ProductCardWithHover';

const BestSellingProducts = ({ products }) => {
  return (
    <section>
      <h2 className="text-3xl font-bold mb-6 text-gray-800">Best Selling Products</h2>
      <div className="grid gap-6 sm:grid-cols-2 lg:grid-cols-4">
        {products.map((product) => (
          <ProductCardWithHover key={product.id} product={product} />
        ))}
      </div>
    </section>
  );
};

export default BestSellingProducts;