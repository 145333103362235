import React from 'react';

const BlogPostCard = ({ title, excerpt, image, date }) => {
  return (
    <div className="bg-white rounded-lg shadow-sm overflow-hidden transition-transform duration-300 hover:scale-105">
      <img src={image} alt={title} className="w-full h-32 object-cover" />
      <div className="p-3">
        <h3 className="text-sm font-semibold text-gray-800 mb-1 truncate">{title}</h3>
        <p className="text-xs text-gray-600 mb-2 line-clamp-2">{excerpt}</p>
        <p className="text-xs text-gray-400">{date}</p>
      </div>
    </div>
  );
};

export default BlogPostCard;