import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { StarIcon, ShoppingCartIcon, ZoomInIcon } from '@heroicons/react/solid';

const ProductCardWithHover = ({ product }) => {
  const [isHovered, setIsHovered] = useState(false);

  const averageRating = product.reviews && product.reviews.length > 0
    ? product.reviews.reduce((sum, review) => sum + review.rating, 0) / product.reviews.length
    : 0;

  const defaultImage = '/path/to/default/image.jpg'; // Replace with your default image path

  return (
    <div 
      className="bg-white rounded-lg overflow-hidden transition-all duration-300 transform hover:scale-105"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div className="relative overflow-hidden">
        <img 
          src={product.image && product.image.length > 0 ? product.image[0] : defaultImage}
          alt={product.name} 
          className="w-full h-48 object-cover transition-transform duration-300 transform hover:scale-110"
        />
        <div className={`absolute top-0 left-0 w-full h-full bg-black bg-opacity-50 flex items-center justify-center transition-opacity duration-300 ${isHovered ? 'opacity-100' : 'opacity-0'}`}>
          <Link to={`/product/${product.id}`} className="text-white hover:text-gray-200">
            <ZoomInIcon className="h-10 w-10" />
          </Link>
        </div>
      </div>
      <div className="p-4">
        <h3 className="text-lg font-semibold mb-2">{product.name}</h3>
        <p className="text-gray-600 mb-2">{product.currency} {product.price}</p>
        <div className={`transition-opacity duration-300 ${isHovered ? 'opacity-0' : 'opacity-100'}`}>
          <div className="flex items-center">
            {[...Array(5)].map((_, index) => (
              <StarIcon 
                key={index} 
                className={`h-5 w-5 ${index < Math.round(averageRating) ? 'text-yellow-400' : 'text-gray-300'}`} 
              />
            ))}
            <span className="ml-2 text-sm text-gray-600">({product.reviews ? product.reviews.length : 0})</span>
          </div>
        </div>
        <div className={`transition-all duration-300 delay-150 ${isHovered ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-4'}`}>
          <button className="w-full bg-blue-500 text-white py-2 rounded hover:bg-blue-600 transition duration-300 flex items-center justify-center">
            <ShoppingCartIcon className="h-5 w-5 mr-2" />
            Add to Cart
          </button>
        </div>
      </div>
    </div>
  );
};

export default ProductCardWithHover;