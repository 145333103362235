import { Link } from 'react-router-dom';
import React, { useState } from 'react';
import { ShoppingCartIcon, ZoomInIcon, StarIcon } from '@heroicons/react/solid';

const EnhancedProductCard = ({ product }) => {
  const [isHovered, setIsHovered] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  // Fallback image in case product images are not available
  const fallbackImage = 'https://via.placeholder.com/300x200?text=No+Image';

  // Check if product and product.image exist and are valid
  const hasValidImages = product && Array.isArray(product.image) && product.image.length > 0;

  const nextImage = () => {
    if (hasValidImages) {
      setCurrentImageIndex((prevIndex) => 
        (prevIndex + 1) % product.image.length
      );
    }
  };

  // Calculate average rating
  const averageRating = product.reviews && product.reviews.length > 0
    ? product.reviews.reduce((sum, review) => sum + review.rating, 0) / product.reviews.length
    : 0;

  // Determine if the product is popular (average rating >= 4)
  const isPopular = averageRating >= 4;

  // If product is undefined, return null or a placeholder
  if (!product) {
    return null; // or return a placeholder component
  }

  return (
    <div 
      className="bg-white rounded-lg shadow-md overflow-hidden"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => {
        setIsHovered(false);
        setCurrentImageIndex(0);
      }}
    >
      <div className="relative">
        <img 
          src={hasValidImages ? product.image[currentImageIndex] : fallbackImage} 
          alt={product.name || 'Product'}
          className="w-full h-48 object-cover transition-all duration-300"
          onMouseMove={nextImage}
        />
        {isHovered && (
          <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center space-x-4">
            <Link to={`/product/${product.id}`} className="p-2 bg-white rounded-full hover:bg-gray-200">
              <ZoomInIcon className="h-6 w-6 text-gray-800" />
            </Link>
            <button className="p-2 bg-white rounded-full hover:bg-gray-200">
              <ShoppingCartIcon className="h-6 w-6 text-gray-800" />
            </button>
          </div>
        )}
      </div>
      <div className="p-4">
        <h3 className="text-sm font-semibold text-gray-800 mb-2">{product.name || 'Unnamed Product'}</h3>
        <p className="text-xs text-gray-600 mb-2">{product.price ? `${product.price} ${product.currency}` : 'Price not available'}</p>
        {isPopular && (
          <div className="flex items-center">
            {[...Array(5)].map((_, index) => (
              <StarIcon
                key={index}
                className={`h-4 w-4 ${
                  index < Math.round(averageRating)
                    ? 'text-yellow-400'
                    : 'text-gray-300'
                }`}
              />
            ))}
            <span className="ml-2 text-xs text-gray-600">
              ({product.reviews.length} reviews)
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

export default EnhancedProductCard;