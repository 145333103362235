import React from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';

const NewArrivalsBanner = ({ products }) => {
  // Assuming the first product is the featured one
  const featuredProduct = products[0];

  return (
    <section className="py-12 relative overflow-hidden">
      {/* Gradient background */}
      <div className="absolute inset-0 bg-gradient-to-br from-amber-300 via-yellow-400 to-yellow-500"></div>
      
      {/* Curved shape */}
      <div className="absolute inset-0">
        <svg viewBox="0 0 1440 320" className="absolute bottom-0 left-0 w-full h-full">
          <path fill="rgba(255,255,255,0.1)" fillOpacity="1" d="M0,96L48,112C96,128,192,160,288,160C384,160,480,128,576,112C672,96,768,96,864,112C960,128,1056,160,1152,160C1248,160,1344,128,1392,112L1440,96L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"></path>
        </svg>
      </div>

      <div className="container mx-auto px-4 relative z-10">
        <div className="flex flex-col md:flex-row items-center justify-between">
          {/* Left Side: Text and CTA */}
          <div className="md:w-1/2 mb-8 md:mb-0">
            <motion.h2 
              className="text-4xl font-bold text-white mb-4"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
            >
              Check out the latest arrivals!
            </motion.h2>
            <motion.p 
              className="text-lg text-white mb-6"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.2 }}
            >
              Discover our newest collection of cutting-edge products.
            </motion.p>
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.4 }}
            >
              <Link 
                to="/new-arrivals"
                className="bg-white text-yellow-500 font-semibold py-2 px-6 rounded-full hover:bg-yellow-100 transition duration-300"
              >
                View All New Arrivals
              </Link>
            </motion.div>
          </div>

          {/* Right Side: Featured Product */}
          <div className="md:w-1/2">
            <motion.div
              className="relative w-full h-80 rounded-lg overflow-hidden"
              initial={{ scale: 0.8, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              transition={{ duration: 0.8 }}
            >
              <img 
                src={featuredProduct.image && featuredProduct.image.length > 0 ? featuredProduct.image[0] : 'https://via.placeholder.com/400'}
                alt={featuredProduct.name}
                className="w-full h-full object-cover"
              />
              <div className="absolute inset-0 bg-gradient-to-t from-black to-transparent opacity-70"></div>
              <div className="absolute bottom-0 left-0 right-0 p-6">
                <h3 className="text-2xl font-bold text-white mb-2">{featuredProduct.name}</h3>
                <p className="text-lg text-yellow-300 mb-4">{featuredProduct.currency} {featuredProduct.price}</p>
                <Link 
                  to={`/product/${featuredProduct.id}`}
                  className="bg-yellow-500 text-white py-2 px-4 rounded-full hover:bg-yellow-600 transition duration-300"
                >
                  View Product
                </Link>
              </div>
            </motion.div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default NewArrivalsBanner;