import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { StarIcon } from '@heroicons/react/solid';

const FeaturedProductBanner = ({ product }) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    if (!product || !product.image || product.image.length <= 1) return;

    const intervalId = setInterval(() => {
      setCurrentImageIndex((prevIndex) => 
        (prevIndex + 1) % product.image.length
      );
    }, 5000); // Change image every 5 seconds

    return () => clearInterval(intervalId);
  }, [product]);

  if (!product) {
    return null; // Or you could return a placeholder/loading state
  }

  const averageRating = product.reviews.reduce((sum, review) => sum + review.rating, 0) / product.reviews.length;

  return (
    <div className="bg-white py-12">
      <div className="container mx-auto px-4">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8 items-center">
          {/* Product Image Carousel */}
          <div className="col-span-1 relative">
            <div className="max-w-xs mx-auto"> {/* Added max-width and center alignment */}
              {product.image && product.image.length > 0 && (
                <img 
                  src={product.image[currentImageIndex]}
                  alt={`${product.name} - Image ${currentImageIndex + 1}`}
                  className="w-full h-auto object-contain rounded-lg p-4" // Added padding, changed to object-contain
                />
              )}
              {product.image && product.image.length > 1 && (
                <div className="absolute bottom-4 left-0 right-0 flex justify-center">
                  {product.image.map((_, index) => (
                    <div
                      key={index}
                      className={`h-2 w-2 rounded-full mx-1 ${
                        index === currentImageIndex ? 'bg-blue-500' : 'bg-gray-300'
                      }`}
                    />
                  ))}
                </div>
              )}
            </div>
          </div>

          {/* Product Details */}
          <div className="col-span-1">
            <h2 className="text-3xl font-bold mb-4">{product.name}</h2>
            <p className="text-gray-600 mb-4">
              {product.description.length > 100 
                ? `${product.description.substring(0, 100)}...` 
                : product.description}
            </p>
            <Link 
              to={`/product/${product.id}`}
              className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 transition duration-300"
            >
              Purchase Now
            </Link>
          </div>

          {/* Rating */}
          <div className="col-span-1">
            <div className="flex flex-col items-center">
              <p className="text-2xl font-bold mb-2">{averageRating.toFixed(1)}</p>
              <div className="flex">
                {[...Array(5)].map((_, index) => (
                  <StarIcon
                    key={index}
                    className={`h-6 w-6 ${
                      index < Math.round(averageRating)
                        ? 'text-yellow-400'
                        : 'text-gray-300'
                    }`}
                  />
                ))}
              </div>
              <p className="mt-2 text-gray-600">({product.reviews.length} reviews)</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FeaturedProductBanner;