import React, { useState, useEffect } from 'react';

const SaleCountdown = ({ endDate }) => {
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  function calculateTimeLeft() {
    const difference = +new Date(endDate) - +new Date();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60)
      };
    }

    return timeLeft;
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearTimeout(timer);
  });

  const formatTime = (time) => time.toString().padStart(2, '0');

  return (
    <div className="flex justify-center space-x-2">
      {Object.keys(timeLeft).length ? (
        <>
          <div className="bg-gray-200 rounded p-2 text-center">
            <span className="text-lg font-bold">{formatTime(timeLeft.days)}</span>
          </div>
          <div className="bg-gray-200 rounded p-2 text-center">
            <span className="text-lg font-bold">{formatTime(timeLeft.hours)}</span>
          </div>
          <div className="bg-gray-200 rounded p-2 text-center">
            <span className="text-lg font-bold">{formatTime(timeLeft.minutes)}</span>
          </div>
          <div className="bg-gray-200 rounded p-2 text-center">
            <span className="text-lg font-bold">{formatTime(timeLeft.seconds)}</span>
          </div>
        </>
      ) : (
        <span className="text-lg font-bold">Time's up!</span>
      )}
    </div>
  );
};

export default SaleCountdown;