import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-6">Privacy Policy</h1>
      <div className="space-y-4">
        <section>
          <h2 className="text-2xl font-semibold mb-2">1. Information We Collect</h2>
          <p>We collect personal information that you provide to us, such as name, email address, shipping address, and payment information when you make a purchase.</p>
        </section>
        <section>
          <h2 className="text-2xl font-semibold mb-2">2. How We Use Your Information</h2>
          <p>We use the information we collect to process your orders, communicate with you about your orders, and provide customer support. We may also use your information to send you marketing communications, subject to your preferences.</p>
        </section>
        <section>
          <h2 className="text-2xl font-semibold mb-2">3. Information Sharing and Disclosure</h2>
          <p>We do not sell or rent your personal information to third parties. We may share your information with service providers who assist us in operating our website and conducting our business.</p>
        </section>
        <section>
          <h2 className="text-2xl font-semibold mb-2">4. Data Security</h2>
          <p>We implement a variety of security measures to maintain the safety of your personal information. However, no method of transmission over the Internet is 100% secure.</p>
        </section>
        <section>
          <h2 className="text-2xl font-semibold mb-2">5. Your Rights</h2>
          <p>You have the right to access, correct, or delete your personal information. Please contact us if you wish to exercise these rights.</p>
        </section>
      </div>
    </div>
  );
};

export default PrivacyPolicy;