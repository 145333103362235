import { Link } from 'react-router-dom';

const EnhancedCategoryCard = ({ category }) => {
  return (
    <div className="flex flex-col bg-white rounded-lg shadow-md overflow-hidden h-full">
      <div className="h-32 flex items-center justify-center overflow-hidden bg-gray-100">
        <img 
          src={category.image} 
          alt={category.name} 
          className="w-24 h-24 object-contain"
        />
      </div>
      <div className="flex-1 p-3 flex flex-col justify-between">
        <div>
          <h3 className="text-lg font-semibold text-gray-800 mb-1">{category.name}</h3>
          <p className="text-gray-600 text-xs mb-2">{category.description}</p>
        </div>
        <Link 
          to={`/category/${category.name.toLowerCase()}`} 
          className="text-blue-600 hover:text-blue-800 transition-colors duration-300 text-sm"
        >
          Shop Now &gt;
        </Link>
      </div>
    </div>
  );
};

export default EnhancedCategoryCard;