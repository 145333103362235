import React, { createContext, useState, useEffect } from "react";

export const AppContext = createContext();

const AppProvider = ({ children }) => {
  const [cart, setCart] = useState([]);
  const [user, setUser] = useState(null);

  // Load cart and user from localStorage
  useEffect(() => {
    const savedCart = JSON.parse(localStorage.getItem("cart")) || [];
    const savedUser = JSON.parse(localStorage.getItem("user")) || null;
    setCart(savedCart);
    setUser(savedUser);
  }, []);

  // Save cart to localStorage
  useEffect(() => {
    localStorage.setItem("cart", JSON.stringify(cart));
  }, [cart]);

  // Save user to localStorage
  useEffect(() => {
    localStorage.setItem("user", JSON.stringify(user));
  }, [user]);

  const addToCart = (product) => {
    setCart((prevCart) => [...prevCart, product]);
  };

  const removeFromCart = (id) => {
    setCart((prevCart) => prevCart.filter((item) => item.id !== id));
  };

  const login = (username) => {
    setUser({ username });
  };

  const logout = () => {
    setUser(null);
    setCart([]);
  };

  return (
    <AppContext.Provider value={{ cart, user, addToCart, removeFromCart, login, logout }}>
      {children}
    </AppContext.Provider>
  );
};

export default AppProvider;
